// when close the alert, it will be hidden for one day
function showAlert() {
    return {
        animate: true,
        cookieName: 'hideAlert',
        setCookie(days = 1) {
            let d = new Date();
            d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = this.cookieName + "=" + !this.show + ";" + expires + ";path=/";
            this.animate = false;
        },
        getCookie() {
            let matches = document.cookie.match( new RegExp(this.cookieName));
            return matches ? decodeURIComponent(matches[1]) : 'true';
        },
        checkCookie() {
            return this.animate = (this.getCookie() === "true");
        }
    }
}
